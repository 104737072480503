
@mixin themed( $property, $key ) {

  #{$property}: map-get($theme, $key);

}

@mixin color-primary(){
  @include themed( 'color', 'primary' );
}
