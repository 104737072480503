@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/settings/clubs.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/settings/colours.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/settings/layout.scss";
@import "settings/colours.scss";

@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/general.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/grid.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/hamburger.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/themes.scss";


body.t-sanfl {

  @include themed( 'background-color', 'primary');


  h1 a {
	background-image: url("/content/themes/common/assets/images/sanfl-logo.svg");
	background-size: contain;
	width: 200px;
	height: 200px;
  }


  #nav a,
  #backtoblog a {

	color: $c-white;

  }

  .button.button-primary {

	@include themed( 'background-color', 'primary');
	@include themed( 'border-color', 'primary');
	border-radius: 0;
	text-shadow: none;
	color: $c-white;
	box-shadow: none;

	&:hover,
	&:focus {
	  background: darken( map-get($theme, primary), 10% );
	  border-color: darken( map-get($theme, primary), 10% );
	  @include shadowed();
	}

  }

}

.login #nav a,
.login #backtoblog a {

  @include themed( 'color', 'secondary');

}
