
$c-white: #fff;
$c-black: #000;
$c-blue: #0f254d;

$c-dark-grey: #4C5565;
$c-medium-grey: #8792A6;
$c-light-grey: #D6D9E2;

$c-background-grey: #edeef2;
$c-extra-light-grey: #f7f7f8;

$c-red: #e73127;
$c-green: #4ea332;

$c-light-yellow: #fff9e3;

$theme: (
    primary: $c-dark-grey,
    highlight: $c-white
);
