body.t-sanfl {
  background-color: #0f254d;
}
body.t-sanfl h1 a {
  background-image: url("/content/themes/common/assets/images/sanfl-logo.svg");
  background-size: contain;
  width: 200px;
  height: 200px;
}
body.t-sanfl #nav a,
body.t-sanfl #backtoblog a {
  color: #fff;
}
body.t-sanfl .button.button-primary {
  background-color: #0f254d;
  border-color: #0f254d;
  border-radius: 0;
  text-shadow: none;
  color: #fff;
  box-shadow: none;
}
body.t-sanfl .button.button-primary:hover, body.t-sanfl .button.button-primary:focus {
  background: #071022;
  border-color: #071022;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}